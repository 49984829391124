<template>
    <section>
        <v-row justify="end" class=" grey darken-4 pb-0">
            <v-col cols="auto" justify="end">
                <v-img class="hidden-sm-and-down" :src="img" max-height="250" />
<v-img class="hidden-sm-and-up"   :src="img" max-height="250" style="z-index: -1;" />

            </v-col>
        </v-row>
    </section>
</template>

<script>
    export default {
        data(){
            return{
                img:'./assets/img/earth_mir.svg',
            }
        }
    }
</script>