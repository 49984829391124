<template>
    <v-container class="py-12 col-12 col-xl-9 col-lg-9">
        <v-row justify="center" align="center">
            <v-col cols="12" align="center">
                <h2>همراه با ارزجت بیشتر یاد بگیرید</h2>
                <div class="mt-3">
                    <img src="/assets/img/ad.gif" />
                </div>
            </v-col>
        </v-row>
        <v-row justify="center" align="center" class="text-center">
            <v-col cols="12" sm="3" v-for="(item, i) in items" :key="i">
                <v-hover v-slot="{ hover }" class="hover">
                    <v-avatar size="70" :color="hover ? 'primary': '' " :class="{ hover }">
                        <v-icon x-large color="secondary">{{ item.icon }}</v-icon>
                    </v-avatar>
                </v-hover>
                <h5 class="hover">{{ item.subText }}</h5>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    export default {
        data(){
            return{
                items:[
                    {icon:'mdi-progress-question', subText:'راهنمای خرید ارز دیجیتال'},
                    {icon:'mdi-television-play', subText:'ویدئوهای آموزشی'},
                    {icon:'mdi-desktop-mac-dashboard', subText:'ارزجت بلاک'},
                    {icon:'mdi-head-question-outline', subText:'سوالات متداول'}
                ]
            }
        }
    }
</script>
