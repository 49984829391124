<template>
  <section>
    <Navigation :currencies="currencies" />

    <v-slide-group next-icon="mdi-chevron-right" class="pb-7">
      <v-slide-item v-for="(item, i) in currencies" :key="i" class="ma-2">
        <v-hover v-slot="{ hover }">
          <v-card
            class="pa-2"
            v-show="item.icon_link"
            elevation="2"
            :hover="hover"
            width="240"
            height="100"
          >
            <v-avatar size="auto" class="mb-1">
              <v-img :src="item.icon_link" width="32" height="32" />
            </v-avatar>
            <span class="mr-2">{{ item.name }}</span>
            <v-card-text class="pa-0">
              <span class="fontGreen">{{ item.buy }}</span>
              <span>(تومان)</span>
            </v-card-text>
            <!-- <v-card-text class="pa-0">
              <span class="fontRed">{{ parseFloat(item.usd) }}$</span>
            </v-card-text> -->
          </v-card>
        </v-hover>
      </v-slide-item>
    </v-slide-group>
  </section>
</template>
<script>
import { mapActions, mapState } from 'vuex';
import Navigation from "./Navigation.vue";

export default {
  components: {
    Navigation,
  },
  data() {
    return {
      model: null,
      timer: null,
    };
  },
  mounted() {
    this.getCurrencies();
    this.timer = setInterval(() => {
      this.getCurrencies();
    }, 30000);
    if (this.$vuetify.breakpoint.xl) {
      return 4;
    }
    if (this.$vuetify.breakpoint.lg) {
      return 3;
    }
    if (this.$vuetify.breakpoint.md) {
      return 2;
    }
    return 1;
  },
  
  unmounted() {
    clearInterval(this.timer);
  },

  computed:{
    ...mapState(["currencies"]),
  },
  methods: {
    ...mapActions(['getCurrencies']),
  },
};
</script>


