import Vue from "vue";

const mutations = {
    setCurrencyList(state, payload) {
        state.currencyList = payload.currencies;
    },

    setCurrencies(state, payload) {
        state.currencies = payload.currencies;
    }
}

export default mutations