import Vue from "vue"
import Vuex from 'vuex'
import axios from '../libs/axios';

import actions from './actions'
import mutations from './mutations'

Vue.use(Vuex);

Vuex.Store.prototype.$axios = axios;

export default new Vuex.Store({
    state: {
        currencyList: [],
        currencies: [],
    },
    getters: {},
    mutations,
    actions
})