<template>
    <section color="blue lighten-4" class="deep-purple lighten-5 py-0 my-0">
        <v-container class="col-12 col-xl-9 col-lg-9">
            <v-row class="bgColor" align="center" justify="space-around">
                <v-col cols="12" sm="7">
                    <h3>دانلود اپلیکیشن ارزجت</h3>
                    <p>
                        ارزجت جهت سهولت کار با سامانه، اقدام به تولید اپلیکیشن های اندروید و آی او اس برای شما عزیزان کرده است.
                    </p>
                    <p>
                        شما می توانید از طریق لینک های زیر اپلیکیشن ارزجت را دانلود کنید.
                    </p>
                    <v-btn link text href="https://cafebazaar.ir/app/com.arzjet.app" max-height="75" height="75">
                        <v-img width="150" src="https://webassets.cafebazaar.ir/images/bazaar-logo.svg" />
                    </v-btn>
                </v-col>
                <v-col cols="12" sm="5">
                    <v-img :src="bannerImage" />
                </v-col>
            </v-row>
        </v-container>
    </section>
</template>

<script>
    export default {
        data(){
            return{
                bannerImage: './assets/img/0000000.webp',
            }
        }
    }
</script>
