<template>
    <transition name="slide-in" mode="out-in">
        <crypto-table />
    </transition>
</template>

<script>
import CryptoTable from './CryptoTable.vue';
    export default {
        components:{ CryptoTable }
    }
</script>