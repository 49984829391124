import Vue from 'vue'
import App from './App.vue'
import router from './router/router'
import store from './store/index'
import Axios from 'axios'
import vuetify from './plugins/vuetify'
import PN from 'persian-number'

Vue.config.productionTip = false

import $ from 'jquery'
window.$ = $

window.Vue = Vue

// Vue.prototype.$http = Axios;
// Axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';

new Vue({
    router,
    PN,
    store,
    vuetify,
    render: h => h(App)
}).$mount('#app')