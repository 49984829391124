<template>
    <section>
        <Header />
        <v-row justify="center" align="center">
            <v-col cols="9" class="mt-n16">
                <h1 class="text-center white--text mb-3">سوالات متداول</h1>
                <v-card outlined flat class="mb-6">
                    <v-card-title class="secondary--text">
                        <v-icon color="secondary" large class="ml-3">mdi-map-marker-question-outline</v-icon>سوالات متداول
                    </v-card-title>
                    <v-card-text>
                        <v-expansion-panels focusable>
                            <v-expansion-panel v-for="(question, i) in questions" :key="i" class="py-1">
                                <v-expansion-panel-header>{{ question.title }}</v-expansion-panel-header>
                                <v-expansion-panel-content class="text-justify pt-3 textSubtitle">{{ question.body }}</v-expansion-panel-content>
                            </v-expansion-panel>
                        </v-expansion-panels>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </section>
</template>

<script>
import Header from './Header.vue'
    export default {
        components:{Header},
        data(){
            return{
                questions:[
                    {
                        title:'ارزجت چیست؟',
                        body:'صرافی ارز دیجیتال ارزجت، یک پلتفرم معامله را آماده کرده‌است که کاربران می‌توانند با ثبت‌نام و احراز هویت در آن، به خرید و فروش ارزهای دیجیتال بپردازند و دارایی خود را در کیف پول اختصاصی خود مدیریت کنند. هدف این است که کاربران ایرانی بتوانند در کمال آسانی و امنیت، بدون واسطه، با یک‌دیگر به معامله بپردازند. تبدیل با پشتیبانی از بیشترین رمزارز در بازار معاملاتی ایران و ارائه‌کردن ابزارهای معاملاتی حرفه‌ای، آمده‌است که نیاز کاربران ایرانی را مرتفع کند تا از دشواری‌های صرافی‌های خارجی و محدودیت‌ها خلاص شوند. کاربران در ارزجت می‌توانند به‌راحتی واریز و برداشت تومانی و خرید و فروش ارز دیجیتال داشته‌باشند.'
                    },
                    {
                        title:'چرا ارز دیجیتال بخریم ؟',
                        body:'سرمایه گذاری در ارز دیجیتال می‌تواند بازار بسیار ساده اما جذاب و پرسودی باشد. ارزهای دیجیتال روز به روز در حال رخنه به دنیای پیرامون ما هستند. دور از انتظارنیست که در آینده ای نزدیک همه تراکنش های مالی و مبادلات صرفا با استفاده از ارزهای دیجیتال صورت بگیرد و تعداد بیشتری از افراد جامعه مشغول به مبادله و خرید و فروش ارز دیجیتال شوند. نکته مثبت و مهم دیگر در خصوص سرمایه گذاری و خرید ارز دیجیتال نقدینگی بسیار بالای این ارزها است. شما میتوانید هر ارز دیجیتالی که در اخیتار دارید را در زمان بسیار کمی به فروش برسانید. یکی دیگر از مزیت های خرید ارز دیجیتال بازدهی بسیار خوب این نوع از سرمایه گذاری است. بازدهی که به خصوص امسال با اختلاف بسیار زیاد از طلا و بورس قرار گرفته است. از دیگر نکات مثبت سرمایه گذاری در ارزهای دیجیتال، انعطاف پذیری این بازار و تنوع بالای موجود در ارزهای دیجیتال است. شما می‌توانید از میان هزاران رمزارز و ارز دیجیتال، چند ارز دیجیتال مورد علاقه خود را انتخاب کرده و یک پرتفوی و سبد متنوع از انواع ارزهای دیجیتال برای خود ایجاد کنید که شانس موفقیت و سوددهی را برای خود بیشتر کنید و در ارزجت به خرید و فروش ارز دیجیتال و مبادله ی آن بپردازید.'
                    },
                    {
                        title:'امنیت در ارزجت چگونه است؟',
                        body:'امنیت تمام و کمال تراکنش‌ها و دارایی‌های شما در ارزجت برعهده شبکه ای پیچیده متشکل از نخبگان دانشگاهی  است. امنیتی که در طی چند سال فعالیت همیشه در بالاترین سطح بوده است. ارزجت برای تأمین امنیت دارایی‌های دیجیتال شما از رویکردهای مختلفی همچون ذخیره‌سازی به شیوه سرد و در کیف پول اختصاصی استفاده می‌کند.'
                    },
                    {
                        title:'چطور شروع کنم؟',
                        body:'کار با ارزجت بسیار ساده است. ابتدا با استفاده از موبایل یا ایمیل شخصی یک حساب کاربری ایجاد نمایید. پس از احراز هویت، می‌توانید مبلغ ریال یا رمزارز مورد نظر خود را به کیف پول داخلی واریز نموده و اقدام به خرید و فروش به صورت آنلاین نمایند.'
                    },
                    {
                        title:'چگونه خرید کنم؟',
                        body:'پس از افتتاح حساب در سایت ارزجت، احراز هویت و تأیید حساب، کاربران محترم می توانند با استفاده از درگاه بانکی اقدام به شارژ حساب ریالی خود نمایند. پس از شارژ حساب، بلافاصله با استفاده از سیستم هوشمند و اتوماتیک  می توانند سفارش گذاری نموده خرید خود را تکمیل نمایند. پس از تکمیل خرید، کیف پول مربوط به ارز دیجیتال خریداری شده به صورت اتوماتیک شارژ خواهد شد. '
                    },
                    {
                        title:'چگونه بفروشم ؟ ',
                        body:'پس از افتتاح حساب در سایت ارزجت، احراز هویت و تأیید حساب توسط تیم امنیتی سایت، کاربران محترم می توانند با استفاده از آدرس اختصاصی، اقدام به انتقال ارز دیجیتال به کیف پول خود نمایند. پس از تأیید تراکنش در شبکه بلاک‌چین (blockchain)،کیف پول مربوط به رمزارز انتقال یافته به صورت اتوماتیک شارژ خواهد شد. پس از این مرحله، این امکان وجود دارد تا کاربر، درخواست فروش رمزارز مورد نظر خود را با قیمت آنلاین و حجم دلخواه ثبت نماید و فروش در لحظه و با سرعت صورت می پذیرد.'
                    },
                    {
                        title:'پشتیبانی ارزجت؟',
                        body:'پشتیبانی خدمات ارزجت به صورت آنی و از طریق تیکت،تلفن ثابت،چت آنلاین ، شبکه های اجتماعی و... پاسخگوی شما می باشد'
                    },
                   
                ]
            }
        }
    }
</script>