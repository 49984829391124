var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('v-container',[_c('v-card',{attrs:{"rounded":""}},[_c('v-card-title',{staticClass:"pb-0 mb-0"},[_c('v-text-field',{attrs:{"outlined":"","dense":"","append-icon":"mdi-magnify","label":"جستجو"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.currencyList,"search":_vm.search,"loading":_vm.currencyList.length === 0,"loading-text":"در حال بروز رسانی ... لطفا کمی صبر کنید","footer-props":{
          itemsPerPageText: 'تعداد در صفحه',
          showCurrentPage: true,
        }},scopedSlots:_vm._u([{key:"item.buy",fn:function(ref){
        var item = ref.item;
return [_c('p',{staticClass:"fontGreen"},[_vm._v(_vm._s(_vm.formatNumber(item.buy)))])]}},{key:"item.sell",fn:function(ref){
        var item = ref.item;
return [_c('p',{staticClass:"fontRed"},[_vm._v(_vm._s(_vm.formatNumber(item.sell)))])]}},{key:"item.usd",fn:function(ref){
        var item = ref.item;
return [_c('p',[_vm._v(_vm._s(_vm.formatNumber(parseFloat(item.usd))))])]}},{key:"item.icon",fn:function(ref){
        var item = ref.item;
return [_c('v-img',{attrs:{"src":item.icon_link,"width":"32","height":"32"}})]}},{key:"item.actions",fn:function(ref){
        var item = ref.item;
return [_c('v-btn',{staticClass:"ml-2",attrs:{"small":"","outlined":"","color":"primary","href":("https://panel.arzjet.com/trade/" + (item.abbreviation)),"target":"_blank"}},[_vm._v("خرید")]),_c('v-btn',{attrs:{"small":"","outlined":"","color":"secondary","href":("https://panel.arzjet.com/trade/" + (item.abbreviation)),"target":"_blank"}},[_vm._v("فروش")])]}}],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }