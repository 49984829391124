<template>
    <section>
        <About />
        <Formation />
        <Nobility />
        <Teams />
    </section>
</template>

<script>
import About from '@/components/about/About.vue';
import Formation from '@/components/about/Formation.vue';
import Nobility from '@/components/about/Nobility.vue';
import Teams from '@/components/about/Teams.vue';
    export default {
        components:{
            About,
            Formation,
            Nobility,
            Teams
        }
    }
</script>