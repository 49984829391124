<template>
    <section>
        <v-row justify="center" align="center">
            <v-col cols="9">
                <v-card height="auto" class="mb-3">
                    <v-card-text class="d-flex flex-wrap justify-space-between align-center">
                        <v-col cols="auto">
                            <v-img :src="step2" width="200" height="190" />
                        </v-col>
                        <v-col cols="12" sm="6">
                            <div class="d-flex flex-wrap py-4">
                                <v-chip color="primary" class="ml-3 mb-2">مسیر دوم</v-chip>
                                <h1 class="secondary--text">راهنمایی پشتیبانی آنلاین</h1>
                            </div>
                            <h4 class="black--text pb-4 text-justify">در هر ساعت شبانه روز از طریق سیستم چت آنلاین (کادر بنفش رنگ در پایین صفحات ) با کارشناسان پشتیبانی ارزجت می‌توانید در ارتباط باشید و سوالات خود را مطرح کنید.</h4>
                            <v-btn color="secondary" small>گفتگوی آنلاین</v-btn>
                        </v-col>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </section>
</template>

<script>
    export default {
        data(){
            return{
                step2:'./assets/img/contact/step2.webp',
            }
        }
    }
</script>