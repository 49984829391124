<template>
    <section>
        <v-row class="darkBg py-8">
            <v-container>
                    <v-col cols="6" align-content="start">
                        <h1>درباره ما</h1>
                        <p>
 ( ارزجت) شکل گرفت تا رویایی محقق گردد. رویای سفر به آینده و ساختن دنیایی که در آن بتوان مرزهای جوامع را طی نماید(metaveres) و در دهکده ای جهانی اقدام به تجارتی به وسعت همین نام نماید(NFT) .
 
					</p>
                    </v-col>
            </v-container>
        </v-row>
        <v-container class="my-0 py-0">
            <v-row justify="center" align="center">
                <v-col cols="12" sm="6">
                    <ul>
                        <li v-for="(item, i) in aboutText" :key="i">
                            <p class="text-justify my-sm-4">{{ item.text }}</p>
                        </li>
                    </ul>
                </v-col>
                <v-col cols="12" sm="6">
                    <v-img :src="src1" />
                </v-col>
            </v-row>
        </v-container>
    </section>
</template>

<script>
    export default {
        data(){
            return{
                src1: './assets/img/about/a1.webp',
                aboutText:[
                    { text:'حفظ و افزایش ارزش پول در دنیای امروز خصوصا در جوامع جهان سوم یکی از اصول اساسی علم تجارت است.'},
                    { text:'(با پولمان چه کار کنیم؟ پولمان را در کدام بانک بگذاریم تا بیشتر سود کنیم؟ چه بخریم تا پولمان بی ارزش نشود؟)'},
                    { text:'این سوال ها ، سوالات تکراری در جهان سوم هستند.اما پاسخ چیست؟'}
                ]
            }
        }
    }
</script>