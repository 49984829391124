import axios from "axios"

export default {
    // Get Crypto CUrrrency 
    async getCurrency({ commit }) {
        return new Promise((resolve, reject) => {
            axios({ 'url': 'https://panel.arzjet.com/api/prices', method: 'GET' })
                .then(resp => {
                    resolve(resp.data)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },

    // Get Posts
    async getPosts({ commit }) {
        return new Promise((resolve, reject) => {
            axios({ 'url': 'https://arzjet.com/blog/wp-json/wp/v2/posts', method: 'GET' })
                .then(resp => {
                    resolve(resp.data)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },

    // Get Currency List
    // mutations.setCurrencyList
    async getCurrencyList({ commit }) {
        try {
            const res = await axios.get('https://api-panel.arzjet.com/api/v1/currency/get');
            commit('setCurrencyList', res.data.data);
        } catch (error) {
            throw new Error ('Error in get currency list');
        }
    },

    async getCurrencies({ commit }) {
        try {
            const res = await axios.get('https://api-panel.arzjet.com/publish/v1/currency');
            commit('setCurrencies', res.data.data);
        } catch (error) {
            throw new Error ('Error in get currency list');
        }
    }
}