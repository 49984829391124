<template>
  <v-container class="col-12 col-xl-9 col-lg-9 pb-10">
    <v-row align="center" justify="center">
      <!-- Exchage columns -->
      <v-col cols="12" sm="7" justify="center" v-if="buy" class="pt-16">
        <ul>
          <li class="d-flex">
            <v-text-field
              class="col-5 pl-1"
              solo
              readonly
              value="تومان"
              flat
              outlined
              disabled
            />
            <v-text-field
              class="col-7"
              :value="TomanUnit"
              reverse
              solo
              placeholder="0"
              flat
              outlined
              readonly
            />
          </li>
          <li class="offset-5 py-0 my-0">
            <v-btn
              @click="buy = !buy"
              color="primary"
              small
              fab
              class="mt-n10 mr-n5"
              style="z-index: 5"
            >
              <v-icon size="60">mdi-sync-circle</v-icon>
            </v-btn>
          </li>
          <li class="d-flex mt-n4">
            <v-select
              solo
              reverse
              class="col-5 pl-1"
              flat
              outlined
              :items="coins"
              v-model="selectedCoin"
              item-text="name"
              return-object
              @change="calculate"
            >
              <template v-slot:prepend-item>
                <v-list-item>
                  <v-list-item-content>
                    <v-text-field
                      hide-details
                      dense
                      label="جستجو"
                      append-icon="mdi-magnify"
                      v-model="filterText"
                      @input="filterCoin"
                    />
                  </v-list-item-content>
                </v-list-item>
              </template>
              <template v-slot:item="{ item, attrs, on }">
                <v-list-item
                  v-on="on"
                  v-bind="attrs"
                  class="hover"
                  v-show="item.icon_link"
                >
                  <v-list-item-content style="z-index: 1000">
                    <v-list-item-title class="d-flex">
                      <v-avatar size="25">
                        <v-img :src="item.icon_link" />
                      </v-avatar>
                      <v-spacer />
                      <span class="textSubtitle">{{ item.name }}</span>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-select>
            <v-text-field
              dir="ltr"
              class="col-7"
              v-model="CoinUnit"
              reverse
              solo
              value="0"
              placeholder="0"
              flat
              outlined
            />
          </li>
          <li>
            <v-row class="navigation-text">
              <v-col cols="12" sm="5" class="mt-n4 px-0">
                <v-col class="d-flex">
                  <span class="greenDot ml-1 mt-1" /><span
                    >قیمت خرید {{ !!selectedCoin && numberFormat(selectedCoin.buy) }}</span
                  >
                  <span class="mr-5">تومان {{  }}</span>
                </v-col>
                <v-col class="d-flex">
                  <span class="redDot ml-1 mt-1" /><span
                    >قیمت فروش {{ !!selectedCoin && numberFormat(selectedCoin.sell) }}</span
                  >
                  <span class="mr-3">تومان {{  }}</span>
                </v-col>
              </v-col>
              <v-col cols="12" sm="7" class="pr-1">
                <v-btn
                  block
                  x-large
                  color="primary"
                  href="https://panel.arzjet.com/"
                  >خرید</v-btn
                >
              </v-col>
            </v-row>
          </li>
        </ul>
      </v-col>

      <v-col cols="12" sm="7" justify="center" v-else class="pt-16">
        <ul>
          <li class="d-flex mb-n4">
            <v-select
              solo
              reverse
              class="col-5 pl-1"
              flat
              outlined
              :items="coins"
              v-model="selectedCoin"
              item-text="name"
              return-object
              @change="calculate"
            >
              <template v-slot:prepend-item>
                <v-list-item>
                  <v-list-item-content>
                    <v-text-field
                      hide-details
                      dense
                      label="جستجو"
                      append-icon="mdi-magnify"
                      v-model="filterText"
                      @input="filterCoin"
                    />
                  </v-list-item-content>
                </v-list-item>
              </template>
              <template v-slot:item="{ item, attrs, on }">
                <v-list-item
                  v-on="on"
                  v-bind="attrs"
                  class="hover"
                  v-show="item.icon_link"
                >
                  <v-list-item-content style="z-index: 1000">
                    <v-list-item-title class="d-flex">
                      <v-avatar size="25">
                        <v-img :src="item.icon_link" />
                      </v-avatar>
                      <v-spacer />
                      <span class="textSubtitle">{{ item.name }}</span>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-select>
            <v-text-field
              dir="ltr"
              class="col-7"
              :value="CoinUnit"
              reverse
              solo
              placeholder="0"
              flat
              outlined
              readonly
            />
          </li>
          <li class="offset-5 py-0 my-0">
            <v-btn
              @click="buy = !buy"
              color="primary"
              small
              fab
              class="mt-n3 mr-n5"
              style="z-index: 100"
            >
              <v-icon size="60">mdi-sync-circle</v-icon>
            </v-btn>
          </li>
          <li class="d-flex">
            <v-text-field
              class="col-5 pl-1"
              solo
              readonly
              value="تومان"
              flat
              outlined
              disabled
            />
            <v-text-field
              dir="ltr"
              class="col-7"
              v-model="TomanUnit"
              reverse
              solo
              placeholder="0"
              flat
              outlined
            />
          </li>

          <li>
            <v-row class="navigation-text">
              <v-col cols="12" sm="5" class="mt-n4 px-0">
                <v-col class="d-flex">
                  <span class="greenDot ml-1 mt-1" /><span
                    >قیمت خرید {{ !!selectedCoin && numberFormat(selectedCoin.buy) }}</span
                  >
                  <span class="mr-5">تومان {{  }}</span>
                </v-col>
                <v-col class="d-flex">
                  <span class="redDot ml-1 mt-1" /><span
                    >قیمت فروش {{ !!selectedCoin && numberFormat(selectedCoin.sell) }}</span
                  >
                  <span class="mr-3">تومان {{  }}</span>
                </v-col>
              </v-col>
              <v-col cols="12" sm="7" class="pr-1">
                <v-btn
                  block
                  x-large
                  color="secondary"
                  href="https://panel.arzjet.com/"
                  >فروش</v-btn
                >
              </v-col>
            </v-row>
          </li>
        </ul>
      </v-col>

      <!-- Banner column -->
      <v-col cols="12" sm="5">
        <v-img :src="logo" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
const partridge = require('partridge/dist/partridge.cjs.js');
export default {
  data() {
    return {
      logo: "./assets/img/banner_decor.png",
      buy: true,
      coins: [],
      price:{
        tomanUnit:'',
        coinUnit:''
      },
      selectedCoin: null,
      filterText: "",
    };
  },
  props: ["currencies"],
  computed:{
    TomanUnit :{
      get(){
        return this.numberFormat(this.price.tomanUnit);
      },
      set(val){
        this.price.tomanUnit = this.resetNumberFormat(val);
      }
    },
    CoinUnit :{
      get(){
        return this.numberFormat(this.price.coinUnit);
      },
      set(val){
        this.price.coinUnit = this.resetNumberFormat(val);
      }
    }
  },
  watch: {
    currencies: function(newVal, oldVal) {
      this.coins = newVal;
      this.selectedCoin = newVal[0];
    },

    'price.coinUnit': function() {
      this.calculate();
    },

    'price.tomanUnit': function() {
      this.calculate();
    }
  },
  methods:{
    numberFormat(number) {
      return partridge.NumberDiscriminator.add(number);
    },

    resetNumberFormat(number) {
      return partridge.NumberDiscriminator.remove(number);
    },

    calculate(){
      if(this.buy){
        this.TomanUnit = this.numberFormat(+this.price.coinUnit * this.selectedCoin.buy);
      } else {
        this.CoinUnit = this.numberFormat(+this.price.tomanUnit / this.selectedCoin.sell);
      }
    },

    filterCoin(){
      this.coins = this.currencies.filter(coin => {
        return coin.name.toLowerCase().includes(this.filterText.toLowerCase());
      });
      this.selectedCoin = this.coins[0];
    },
  }

};
</script>