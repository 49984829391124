<template>
    <v-container>
        <v-card color="white" rounded class="py-8 px-16" elevation="2">
            <h2 class="text-center secondary--text mb-3">شکل گیری</h2>
            <p class="text-justify">{{ text }}</p>
        </v-card>
    </v-container>
</template>

<script>
    export default {
        data(){
            return{
                text:'ارزجت ، مجموعه‌ای از متخصصین با تجربه در حوزه ها‌ی مالی، سرمایه گذاری ،تحلیلگران اقتصادی و تکنولوژی است که در تلاشند تا با ترکیب تکنولوژی و علوم اقتصادی و مالی،‌ ابرازهایی ساده ، کارآمد و تخصصی در اختیار کاربران خود قرار دهند تا افراد، با هر سطح دانش و تجربه ای بتوانند در حوزه ارزهای دیجیتال و به وسعت جهان سرمایه گذاری کنند و به تجارت بپردازند.'
            }
        }
    }
</script>