import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import 'vuetify/dist/vuetify.min.css';

Vue.use(Vuetify);
const theme = {
    primary: "#03c5cc",
    secondary: "#6c6eff",
    backgroundColor: "#f5fbf9"
}
export default new Vuetify({
    rtl: true,
    theme: {
        themes: {
            dark: theme,
            light: theme
        }
    },
    icons: {
        iconfont: 'mdiSvg' || 'mdi'
    },
});