<template>
    <div>
        <Header />
        <step-one />
        <step-two />
        <step-three />
        <step-four />
    </div>
</template>

<script>
import Header from './Header.vue'
import StepFour from './StepFour.vue'
import StepOne from './StepOne.vue'
import StepThree from './StepThree.vue'
import StepTwo from './StepTwo.vue'
    export default {
        components:{Header, StepOne, StepTwo, StepThree, StepFour}
    }
                
</script>