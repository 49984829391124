<template>
    <v-slide-group next-icon="mdi-chevron-right">
        <v-slide-item v-for="(item, i) in posts" :key="i" class="ma-5">
            <v-hover v-slot="{ hover }">
                <v-card rounded :hover="hover" :href="item.link" height="350" width="320">
                    <v-card-title>
                        <v-img :src="item.featured_image_url" height="150">
                            <template v-slot:palceholder>
                                <v-row justify="center" align="center" class="fill-height ma-0">
                                    <v-progress-circular indeterminate color="primary" />
                                </v-row>
                            </template>
                        </v-img>
                    </v-card-title>
                    <v-card-text>
                            <div class="text-caption text-left">{{ item.date | slice }}</div>
                        <h4>{{ item.title.rendered }}</h4>
                    </v-card-text>
                    <v-card-subtitle>
                        <span class="text-justify" v-html="item.excerpt.rendered.substring(0, 100)" />
                    </v-card-subtitle>
                </v-card>
            </v-hover>
        </v-slide-item>
    </v-slide-group>
</template>

<script>
    export default {
        data(){
            return{
                posts:[],
            }
        },
        mounted(){
            this.getPosts()
        },
        methods:{
            getPosts(){
                this.$store.dispatch('getPosts')
                .then(resp=>{
                    this.posts = resp
                })
                .catch(err=>{

                })
            }
        },
        filters:{
            slice(value){
                return value.slice(0,10)
            }
        }
    }
</script>
