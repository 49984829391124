<template>
  <section>
    <v-container>
      <v-card rounded>
        <v-card-title class="pb-0 mb-0">
          <v-text-field
            outlined
            dense
            v-model="search"
            append-icon="mdi-magnify"
            label="جستجو"
          />
        </v-card-title>
        <v-data-table
          :headers="headers"
          :items="currencyList"
          :search="search"
          :loading="currencyList.length === 0"
          loading-text="در حال بروز رسانی ... لطفا کمی صبر کنید"
          :footer-props="{
            itemsPerPageText: 'تعداد در صفحه',
            showCurrentPage: true,
          }"
        >
          <template v-slot:[`item.buy`]="{ item }">
            <p class="fontGreen">{{ formatNumber(item.buy) }}</p>
          </template>
          <template v-slot:[`item.sell`]="{ item }">
            <p class="fontRed">{{ formatNumber(item.sell) }}</p>
          </template>
          <template v-slot:[`item.usd`]="{ item }">
            <p>{{ formatNumber(parseFloat(item.usd)) }}</p>
          </template>
          <template v-slot:[`item.icon`]="{ item }">
            <v-img :src="item.icon_link" width="32" height="32" />
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-btn
              small
              outlined
              color="primary"
              :href="`https://panel.arzjet.com/trade/${item.abbreviation}`"
              target="_blank"
              class="ml-2"
              >خرید</v-btn
            >
            <v-btn
              small
              outlined
              color="secondary"
              :href="`https://panel.arzjet.com/trade/${item.abbreviation}`"
              target="_blank"
              >فروش</v-btn
            >
          </template>
        </v-data-table>
      </v-card>
    </v-container>
  </section>
</template>

<script>
import { mapActions, mapState } from "vuex";
const partridge = require("partridge/dist/partridge.cjs.js");
export default {
  data() {
    return {
      timer: null,
      currency: [],
      search: "",
      headers: [
        { text: "", align: "start", value: "icon" },
        { text: "نماد", align: "start", value: "name" },
        { text: "قیمت جهانی (به دلار )", value: "usd" },
        { text: "قیمت خرید از ما(تومان)", value: "buy" },
        { text: "قیمت فروش به ما(تومان)", value: "sell" },
        { text: "", value: "actions" },
      ],
    };
  },
  mounted() {
    this.getCurrencyList();
    this.timer = setInterval(() => {
      this.getCurrencyList();
    }, 59000);
  },
  unmounted() {
    clearInterval(this.timer);
  },
  computed: {
    ...mapState(["currencyList"]),
  },
  methods: {
    ...mapActions(["getCurrencyList"]),
    formatNumber(number) {
      return partridge.NumberDiscriminator.add(number);
    },
  },
};
</script>