<template>
  <section>
    <v-footer dark padless>
      <v-container class="col-12 col-xl-9 col-lg-9">
        <!-- Top footer -->
        <v-row justify="center" align="center" class="py-4 px-0">
          <v-col cols="12" class="pb-12">
            <v-row justify="space-between" align="center">
              <v-col cols="12" md="3" class="flex-column">
                <h3 class="text-center mb-2">ارزجت</h3>
                <v-btn
                  color="transparent"
                  dark
                  :to="{ name: 'home' }"
                  text
                  x-large
                  class="d-flex mx-auto"
                >
                  <v-img :src="logo" />
                </v-btn>
              </v-col>
              <v-col
                v-for="(item, i) in items"
                :key="i"
                cols="12"
                md="3"
                sm="4"
              >
                <ul>
                  <h3>{{ item.header }}</h3>
                  <hr class="my-2" />
                  <a
                    class="link py-1"
                    v-for="(item1, j) in item.child"
                    @click="handleLinkFooter(item1)"
                    :key="j"
                  >
                    <div class="textSubtitle">{{ item1.title }}</div>
                  </a>
                </ul>
              </v-col>

              <v-col>
                <img referrerpolicy='origin' id = 'rgvjjxlzrgvjoeukjzpejzpe' style = 'cursor:pointer' onclick = 'window.open("https://logo.samandehi.ir/Verify.aspx?id=313877&p=xlaorfthxlaomcsijyoejyoe", "Popup","toolbar=no, scrollbars=no, location=no, statusbar=no, menubar=no, resizable=0, width=450, height=630, top=30")' alt = 'logo-samandehi' src = 'https://logo.samandehi.ir/logo.aspx?id=313877&p=qftinbpdqftiaqgwyndtyndt' />
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-footer>
    <v-divider />
    <!-- Bottom Footer -->
    <v-footer dark inset padless>
      <v-container class="col-12 col-xl-9 col-lg-9">
        <v-row>
          <v-col cols="12" sm="6" class="text-center">
            <v-btn
              fab
              small
              elevation="1"
              class="mr-4"
              v-for="(item, i) in socialMedia"
              :key="i"
              :href="item.link"
            >
              <v-icon color="white">{{ item.icon }}</v-icon>
            </v-btn>
          </v-col>
          <v-col
            cols="12"
            sm="6"
            class="d-flex justify-end align-center text-center"
          >
            <div class="textSubtitle">
              © تمام حقوق مادی و معنوی این سایت متعلق به ارزجت است.
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-footer>
  </section>
</template>

<script>
export default {
  data() {
    return {
      logo: "./assets/img/logo.png",
      btns: [
        { text: "دریافت مستقیم", icon: "mdi-shopping" },
        { text: "دریافت از بازار", icon: "mdi-basket" },
        { text: "ویژه دستگاههای هوآوی", icon: "mdi-cellphone" },
        { text: "دریافت از مایکت", icon: "mdi-microsoft-azure" },
      ],
      socialMedia: [
        { icon: "mdi-instagram", link: "https://instagram.com/arzjet" },
        { icon: "mdi-twitter", link: "" },
        { icon: "mdi-linkedin", link: "" },
        { icon: "mdi-facebook-messenger", link: "" },
      ],
      items: [
        {
          header: "فهرست راهبردی ارزجت",
          child: [
            { link: "about", title: "درباره ما" },
            { link: "/", title: "ارزجت" },
            { link: "/", title: "امنیت کیف پول" },
            { link: "terms", title: "قوانین و مقررات" },
            { link: "contact-us", title: "تماس با ما" },
          ],
        },
        {
          header: "امکانات و خدمات",
          child: [
            { link: "/", title: "بازار آزمایشی ارزجت" },
            { link: "/", title: "معرفی به دوستان" },
            {
              link: "/assets/app/arzjet.apk",
              title: "اپلیکیشن اندروید",
              native: true,
            },
            {
              link: "https://app.arzjet.com/",
              title: " وب اپلیکیشن",
              native: true,
            },
            { link: "/", title: "مستندات API" },
            { link: "/", title: "Changelog" },
          ],
        },
        {
          header: "راهنما و پشتیبانی",
          child: [
            { link: "/", title: "احراز هویت و سطوح کاربری" },
            { link: "faq", title: "سوالات متداول" },
            { link: "/", title: "قیمت ها و کارمزدها" },
            { link: "/", title: "راهنمای امنیت حساب کاربری" },
          ],
        },
      ],
    };
  },

  methods: {
    handleLinkFooter(item) {
      if (item.native) {
        window.open(item.link, "_blank");
      } else {
        this.$router.push({ name: item.link });
      }
    },
  },
};
</script>
