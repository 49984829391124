<template>
  <section v-if="$route.name == 'home'">
    <price-slider />
    <Applications />
    <Banner />
    <card-slider />
    <Feature />
    <Guide />
    <contact-us />
  </section>
</template>

<script>
import PriceSlider from "./PriceSlider.vue";
import Applications from "./Applications.vue";
import Banner from "./Banner.vue";
import CardSlider from "./PostSlider.vue";
import Feature from "./Feature.vue";
import Guide from "./Guide.vue";
import ContactUs from "./ContactUs.vue";
export default {
  components: {
    PriceSlider,
    Applications,
    Banner,
    CardSlider,
    Feature,
    Guide,
    ContactUs,
  }
};
</script>
