<template>
  <v-app color="backgroundColor">
    <v-main>
      <Header />
      <router-view />
      <Footer />
    </v-main>
  </v-app>
</template>

<script>

import Header from '@/components/home/Header.vue';
import Footer from '@/components/home/Footer.vue';
export default {
  name: 'App',
  components:{
    Header,Footer
  }
};
</script>
