<template>
    <section class="py-12 mt-8 banner">
        <v-container class="col-12 col-xl-9 col-lg-9">
            <v-row justify="space-between" align="center" class="text-center">
                <v-col cols="12" xl="3" sm="4" v-for="(item, i) in items" :key="i">
                    <div class="pa-2">
                        <v-img :src="item.img" height="120" width="90" class="d-flex mx-auto img" />
                    </div>
                    <h3 class="textTitle my-3">{{ item.title }}</h3>
                    <p class="px-16 textSubtitle">{{ item.subtitle }}</p>
                </v-col>
            </v-row>
        </v-container>
    </section>
</template>

<script>
    export default {
        data(){
            return{
                items:[
                    {img:'./assets/img/guide/g1.webp', title:'کیف پول مطمئن', subtitle:'بهترین کیف پول و مطمئن در حوزه ارزهای دیجیتال که با خیالی راحت دارایی های خود را ذخیره کنید'},
                    {img:'./assets/img/guide/g2.webp', title:'نگهداری تمام دارایی های شما', subtitle:'نگهداری تمام ارزهای دیجیتال های شما در کیف پولی مطمئن و امن '},
                    {img:'./assets/img/guide/g3.webp', title:'پیشرو و سریع', subtitle:'تنوع رمزازرها و سرعت معاملات، ارزجت را جز بهترین بازارهای آنلاین قرار داده است.'},
                ]
            }
        }
    }
</script>