import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../components/home/Index.vue'
import About from '../components/about/Index.vue'
import CryptoTable from '../components/crypto/Index.vue'
import ContactUs from '@/components/contact/Index.vue';
import MostQuestion from '@/components/contact/MostQuestion.vue';
import Terms from '@/components/contact/Terms.vue';

Vue.use(VueRouter)

const routes = [{
        path: '/',
        name: 'home',
        component: Home
    },
    {
        path: '/about',
        name: 'about',
        component: About
    },
    // Symbols table
    {
        path: '/current-prices',
        name: 'current-prices',
        component: CryptoTable
    },
    // Contact Us
    {
        path: '/contact-us',
        name: 'contact-us',
        component: ContactUs
    },
	// Terms
    {
        path: '/terms',
        name: 'terms',
        component: Terms
    },
    // Most Question
    {
        path: '/faq',
        name: 'faq',
        component: MostQuestion
    }
	
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router